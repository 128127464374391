<template>
  <div id="app">
    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
    <h4>加法</h4>
    
    <el-checkbox-group v-model="checkedList" @change="handleCheckedChange">
      <el-checkbox label="addC">进位</el-checkbox>
      <el-checkbox label="addN">不进位</el-checkbox>
    </el-checkbox-group>
    <h4>减法</h4>
    <el-checkbox-group v-model="checkedList" @change="handleCheckedChange">
      <el-checkbox label="subC">退位</el-checkbox>
      <el-checkbox label="subN">不退位</el-checkbox>
    </el-checkbox-group>
    <h4>乘法</h4>
    <el-checkbox-group v-model="checkedList" @change="handleCheckedChange">
      <el-checkbox v-for="item in mulList" :label="item" :key="item">{{ item.replace('mul', '') }}</el-checkbox>
    </el-checkbox-group>
    <h4>除法</h4>
    <el-checkbox-group v-model="checkedList" @change="handleCheckedChange">
      <el-checkbox v-for="item in divList" :label="item" :key="item">{{ item.replace('div', '') }}</el-checkbox>
    </el-checkbox-group>
    <el-row class="row">
      <span>题数 </span>
      <el-input-number v-model="num" size="mini"></el-input-number>
      <el-button class="btn" type="primary" size="mini" @click="createList">生成</el-button>
    </el-row>
    
    <div class="content" id="capture">
      <div class="item" v-for="(str, i) in list" :key="i">{{ str }}</div>
    </div>
    <img class="img" :src="src">
  </div>
</template>

<script>
import html2canvas from 'html2canvas';

const addList = ['addC', 'addN']
const subList = ['subC', 'subN']
const mulList = ['mul2','mul3','mul4','mul5','mul6','mul7','mul8','mul9']
const divList = ['div2','div3','div4','div5','div6','div7','div8','div9']
const allList = [...addList, ...subList, ...mulList, ...divList]

const mulMap = {}
const divMap = {}
for (let i = 2; i < 9; i++) {
  const m = []
  const d = []
  for (let j = 2; j < 9; j++) {
    m.push(`${i} × ${j} =`)
    d.push(`${i * j} ÷ ${i} =`)
  }
  mulMap[i] = m
  divMap[i] = d
}


export default {
  name: 'App',
  data() {
    return {
      checkAll: false,
      checkedList: [],
      isIndeterminate: false,
      num: 100,
      addList,
      subList,
      mulList,
      divList,
      list: [],
      mulMap: [],
      src: ''
    }
  },
  methods: {
    handleCheckAllChange(val) {
      this.checkedList = val ? [...allList] : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === allList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < allList.length;
    },
    random() {
      const list = this.checkedList
      const i = Math.floor((Math.random()*list.length));
      return list[i]
    },
    randomList(list) {
      const i = Math.floor((Math.random()*list.length));
      return list[i]
    },
    randomAddFirst(type) {
      const num = Math.floor(Math.random() * 45) + 5;
      if (type === 'c') {
        return num % 10 < 2 ? num + Math.floor(Math.random() * 5) + 2 : num 
      } else {
        return num % 10 > 8 ? num - Math.ceil(Math.random() * 5) : num 
      }
    },
    randomAddUnit(num) {
      const rest = 10 - num
      return Math.ceil(Math.random() * (num - 1)) + rest;
    },
    randomTenAdd(num) {
      return Math.floor(Math.random() * (8 - num)) || '';
    },
    randomAddSecond(num) {
      const ten = parseInt(num/10);
      const unit = num % 10;
      const str = `${this.randomTenAdd(ten)}${this.randomAddUnit(unit)}`
      return str
    },
    createAddC() {
      const first = this.randomAddFirst('c')
      const second = this.randomAddSecond(first)
      const str = `${first} + ${second} = `
      this.list.push(str)
    },
    randomAddUnitN(num) {
      return Math.ceil(Math.random() * (9 - num));
    },
    randomAddSecondN(num) {
      const ten = parseInt(num/10);
      const unit = num % 10;
      const str = `${this.randomTenAdd(ten)}${this.randomAddUnitN(unit)}`
      return str
    },
    createAddN() {
      const first = this.randomAddFirst('n')
      const second = this.randomAddSecondN(first)
      const str = `${first} + ${second} = `
      this.list.push(str)
    },

    randomSubFirst(type) {
      let num = Math.floor(Math.random() * 75) + 5;
      if (type === 'c') {
        if (num < 10) {
          num = num + Math.floor(Math.random() * 20) + 10
        }
        return num % 10 > 8 ? num - Math.ceil(Math.random() * 5) : num 
      } else {
        return num % 10 < 2 ? num + Math.floor(Math.random() * 5) + 4 : num 
      }
    },
    randomSubTen(num) {
      return Math.floor(Math.random() * num) || '';
    },

    randomSubUnit(num) {
      return Math.ceil(Math.random() * (9 - num)) + num;
    },
    randomSubSecond(num) {
      const ten = parseInt(num/10);
      const unit = num % 10;
      const str = `${this.randomSubTen(ten)}${this.randomSubUnit(unit)}`
      return str
    },
    createSubC() {
      const first = this.randomSubFirst('c')
      const second = this.randomSubSecond(first)
      const str = `${first} - ${second} = `
      this.list.push(str)
    },
    randomSubUnitN(num) {
      return Math.floor(Math.random() * num) || Math.ceil(num - Math.random() * num);
    },
    randomSubSecondN(num) {
      const ten = parseInt(num/10);
      const unit = num % 10;
      const str = `${this.randomSubTen(ten)}${this.randomSubUnitN(unit)}`
      return str
    },
    createSubN() {
      const first = this.randomSubFirst('n')
      const second = this.randomSubSecondN(first)
      const str = `${first} - ${second} = `
      this.list.push(str)
    },

    createList() {
      this.list = []
      for(let i = 0; i < this.num; i++) {
        const type = this.random()
        console.log(type);
        if (type === 'addC') {
          this.createAddC()
        } else if (type === 'addN') {
          this.createAddN()
        } else if (type === 'subC') {
          this.createSubC()
        } else if (type === 'subN') {
          this.createSubN()
        } else if (type.includes('mul')) {
          const k = type.replace('mul', '')
          const mList = mulMap[k]
          this.list.push(this.randomList(mList))
        } else if (type.includes('div')) {
          const k = type.replace('div', '')
          const dList = divMap[k]
          this.list.push(this.randomList(dList))
        }
      } 

      setTimeout(() => {
        this.toImage()
      }, 100)
    },

    // 生成截图
    toImage () {
      html2canvas(document.querySelector('#capture'), {
        useCORS: true, // 【重要】开启跨域配置
        scale: window.devicePixelRatio < 3 ? window.devicePixelRatio : 2,
        allowTaint: true, // 允许跨域图片
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg');
        this.src = imgData
      });

    }
  }

}
</script>

<style>
body {
  margin: 0;
  overflow-x: hidden;
}
h4 {
  margin: 5px 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  padding: 20px 20px 0 20px;
}
.row {
  margin: 20px 0;
}
.btn {
  margin-left: 50px !important;
}
.content {
  width: 800px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  position: absolute;
  width: 850px;
  right: 2000px;
  padding-left: 50px;
}
.item {
  width: 200px;
  margin-bottom: 10px;
  color: #000;
  flex-shrink: 0;
}
.img {
  width: 800px;
}
</style>
